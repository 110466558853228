import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Terms of use" />
        <PageHeader title="Terms of use" />
        <div
          className="container"
          style={{
            padding: 40,
          }}
        >
          <p data-custom-class="title">
            <b>
              <strong>END USER LICENSE AGREEMENT</strong>
            </b>
          </p>
          <b>
            <strong>
              <span data-custom-class="body_text">Last updated </span>
            </strong>
          </b>
          <b>
            <strong>
              <span data-custom-class="body_text">July 23, 2020</span>
            </strong>
          </b>
          <p data-custom-class="body_text">
            trackmylifts is licensed to You (End-User) by Bluebox Digital Ltd,
            located at 71-75 Shelton Street, London, WC2H 9JQ, United
            Kingdom (hereinafter: Licensor), for use only under the terms of
            this License Agreement.
          </p>
          <p data-custom-class="body_text">
            By downloading the Application from the Apple AppStore, and any
            update thereto (as permitted by this License Agreement), You
            indicate that You agree to be bound by all of the terms and
            conditions of this License Agreement, and that You accept this
            License Agreement.
          </p>
          <p data-custom-class="body_text">
            The parties of this License Agreement acknowledge that Apple is not
            a Party to this License Agreement and is not bound by any provisions
            or obligations with regard to the Application, such as warranty,
            liability, maintenance and support thereof. Bluebox Digital Ltd, not
            Apple, is solely responsible for the licensed Application and the
            content thereof.
          </p>
          <p data-custom-class="body_text">
            This License Agreement may not provide for usage rules for the
            Application that are in conflict with the latest 
            <a
              href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
              data-custom-class="link"
            >
              App Store Terms of Service
            </a>
            . Bluebox Digital Ltd acknowledges that it had the opportunity to
            review said terms and this License Agreement is not conflicting with
            them.
          </p>
          <p data-custom-class="body_text">
            All rights not expressly granted to You are reserved.
          </p>
          <ol>
            <li data-custom-class="heading_1">
              <b>
                <strong>THE APPLICATION</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            trackmylifts (hereinafter: Application) is a piece of software
            created to to help users build muscle - and customized for Apple
            mobile devices. It is used to tracking and predicting weight lifting
            sessions.
          </p>
          <ol start="2">
            <li data-custom-class="heading_1">
              <b>
                <strong> SCOPE OF LICENSE</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            2.1  You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed
            Application on any Apple-branded Products that You (End-User) own or
            control and as permitted by the Usage Rules set forth in this
            section and the App Store Terms of Service, with the exception that
            such licensed Application may be accessed and used by other accounts
            associated with You (End-User, The Purchaser) via Family Sharing or
            volume purchasing.
          </p>
          <p data-custom-class="body_text">
            2.2  This license will also govern any updates of the Application
            provided by Licensor that replace, repair, and/or supplement the
            first Application, unless a separate license is provided for such
            update in which case the terms of that new license will govern.
          </p>
          <p data-custom-class="body_text">
            2.3  You may not share or make the Application available to third
            parties (unless to the degree allowed by the Apple Terms and
            Conditions, and with Bluebox Digital Ltd's prior written consent),
            sell, rent, lend, lease or otherwise redistribute the Application.
          </p>
          <p data-custom-class="body_text">
            2.4  You may not reverse engineer, translate, disassemble,
            integrate, decompile, integrate, remove, modify, combine, create
            derivative works or updates of, adapt, or attempt to derive the
            source code of the Application, or any part thereof (except
            with Bluebox Digital Ltd's prior written consent).
          </p>
          <p data-custom-class="body_text">
            2.5  You may not copy (excluding when expressly authorized by this
            license and the Usage Rules) or alter the Application or portions
            thereof. You may create and store copies only on devices that You
            own or control for backup keeping under the terms of this license,
            the App Store Terms of Service, and any other terms and conditions
            that apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time.
          </p>
          <p data-custom-class="body_text">
            2.6  Violations of the obligations mentioned above, as well as the
            attempt of such infringement, may be subject to prosecution and
            damages.
          </p>
          <p data-custom-class="body_text">
            2.7  Licensor reserves the right to modify the terms and conditions
            of licensing.
          </p>
          <p data-custom-class="body_text">
            2.8  Nothing in this license should be interpreted to restrict
            third-party terms. When using the Application, You must ensure that
            You comply with applicable third-party terms and conditions.
          </p>
          <ol start="3">
            <li data-custom-class="heading_1">
              <b>
                <strong> TECHNICAL REQUIREMENTS</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            3.1  You acknowledge that it is Your responsibility to confirm and
            determine that the app end-user device on which You intend to use
            the Application satisfies the technical  specifications mentioned
            above.
          </p>
          <p data-custom-class="body_text">
            3.2  Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </p>
          <ol start="4">
            <li data-custom-class="heading_1">
              <b>
                <strong> NO MAINTENANCE OR SUPPORT</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            4.1  Bluebox Digital Ltd is not obligated, expressed or implied, to
            provide any maintenance, technical or other support for the
            Application.
          </p>
          <p data-custom-class="body_text">
            4.2  Bluebox Digital Ltd and the End-User acknowledge that Apple has
            no obligation whatsoever to furnish any maintenance and support
            services with respect to the licensed Application.
          </p>
          <ol start="5">
            <li data-custom-class="heading_1">
              <b>
                <strong> USE OF DATA</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded licensed Application content and Your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy: 
            <a
              href="https://trackmylifts.io/privacy-policy"
              data-custom-class="link"
            >
              https://trackmylifts.io/privacy-policy
            </a>
            .
          </p>
          <ol start="6">
            <li data-custom-class="heading_1">
              <b>
                <strong> LIABILITY</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            6.1  Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 2 of
            this Agreement. To avoid data loss, You are required to make use of
            backup functions of the Application to the extent allowed by
            applicable third-party terms and conditions of use. You are aware
            that in case of alterations or manipulations of the Application, You
            will not have access to licensed Application.
          </p>
          <ol start="7">
            <li data-custom-class="heading_1">
              <b>
                <strong> WARRANTY</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            7.1  Licensor warrants that the Application is free of spyware,
            trojan horses, viruses, or any other malware at the time of Your
            download. Licensor warrants that the Application works as described
            in the user documentation.
          </p>
          <p data-custom-class="body_text">
            7.2  No warranty is provided for the Application that is not
            executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of Bluebox Digital Ltd's sphere
            of influence that affect the executability of the Application.
          </p>
          <p data-custom-class="body_text">
            7.3  You are required to inspect the Application immediately after
            installing it and notify Bluebox Digital Ltd about issues discovered
            without delay by e-mail provided in 
            <a
              href="https://app.termly.io/dashboard/website/517987/terms-of-service#productclaims"
              data-custom-class="link"
            >
              Product Claims
            </a>
            . The defect report will be taken into consideration and further
            investigated if it has been mailed within a period
            of __________ days after discovery.
          </p>
          <p data-custom-class="body_text">
            7.4  If we confirm that the Application is defective, Bluebox
            Digital Ltd reserves a choice to remedy the situation either by
            means of solving the defect or substitute delivery.
          </p>
          <p data-custom-class="body_text">
            7.5  In the event of any failure of the Application to conform to
            any applicable warranty, You may notify the App-Store-Operator, and
            Your Application purchase price will be refunded to You. To the
            maximum extent permitted by applicable law, the App-Store-Operator
            will have no other warranty obligation whatsoever with respect to
            the App, and any other losses, claims, damages, liabilities,
            expenses and costs attributable to any negligence to adhere to any
            warranty.
          </p>
          <p data-custom-class="body_text">
            7.6  If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Application was made available to the user.
            The statutory periods of limitation given by law apply for users who
            are consumers.
          </p>
          <ol start="8">
            <li data-custom-class="heading_1">
              <b>
                <strong> PRODUCT CLAIMS</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            Bluebox Digital Ltd and the End-User acknowledge that Bluebox
            Digital Ltd, and not Apple, is responsible for addressing any claims
            of the End-User or any third party relating to the licensed
            Application or the End-User’s possession and/or use of that licensed
            Application, including, but not limited to:
          </p>
          <p data-custom-class="body_text">(i) product liability claims;</p>
          <p data-custom-class="body_text">
            (ii) any claim that the licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </p>
          <p data-custom-class="body_text">
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application’s use of the HealthKit and HomeKit.
          </p>
          <ol start="9">
            <li data-custom-class="heading_1">
              <b>
                <strong> LEGAL COMPLIANCE</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            You represent and warrant that You are not located in a country that
            is subject to a U.S. Government embargo, or that has been designated
            by the U.S. Government as a "terrorist supporting" country; and that
            You are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </p>
          <ol start="10">
            <li data-custom-class="heading_1">
              <b>
                <strong> CONTACT INFORMATION                </strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            For general inquiries, complaints, questions or claims concerning
            the licensed Application, please contact:
          </p>
          &nbsp;
          <p data-custom-class="body_text">Ben Wright</p>
          <p data-custom-class="body_text">71-75 Shelton Street</p>
          <p data-custom-class="body_text">London, __________ WC2H 9JQ</p>
          <p data-custom-class="body_text">United Kingdom</p>
          <p data-custom-class="body_text">hello@blueboxdigital.co.uk</p>
          <ol start="11">
            <li data-custom-class="heading_1">
              <b>
                <strong> TERMINATION</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            The license is valid until terminated by Bluebox Digital Ltd or by
            You. Your rights under this license will terminate automatically and
            without notice from Bluebox Digital Ltd if You fail to adhere to any
            term(s) of this license. Upon License termination, You shall stop
            all use of the Application, and destroy all copies, full or partial,
            of the Application.
          </p>
          <ol start="12">
            <li data-custom-class="heading_1">
              <b>
                <strong>
                  {" "}
                  THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
                </strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            Bluebox Digital Ltd represents and warrants that Bluebox Digital
            Ltd will comply with applicable third-party terms of agreement when
            using licensed Application.
          </p>
          <p data-custom-class="body_text">
            In Accordance with Section 9 of the "Instructions for Minimum Terms
            of Developer's End-User License Agreement," Apple and Apple's
            subsidiaries shall be third-party beneficiaries of this End User
            License Agreement and - upon Your acceptance of the terms and
            conditions of this license agreement, Apple will have the right (and
            will be deemed to have accepted the right) to enforce this End User
            License Agreement against You as a third-party beneficiary thereof.
          </p>
          <ol start="13">
            <li data-custom-class="heading_1">
              <b>
                <strong> INTELLECTUAL PROPERTY RIGHTS</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            Bluebox Digital Ltd and the End-User acknowledge that, in the event
            of any third-party claim that the licensed Application or the
            End-User's possession and use of that licensed Application infringes
            on the third party's intellectual property rights, Bluebox Digital
            Ltd, and not Apple, will be solely responsible for the
            investigation, defense, settlement and discharge or any such
            intellectual property infringement claims.
          </p>
          <ol start="14">
            <li data-custom-class="heading_1">
              <b>
                <strong> APPLICABLE LAW</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            This license agreement is governed by the laws of the United
            Kingdom excluding its conflicts of law rules.
          </p>
          <ol start="15">
            <li data-custom-class="heading_1">
              <b>
                <strong> MISCELLANEOUS</strong>
              </b>
            </li>
          </ol>
          <p data-custom-class="body_text">
            15.1  If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.
          </p>
          &nbsp;
          <p data-custom-class="body_text">
            15.2  Collateral agreements, changes and amendments are only valid
            if laid down in writing. The preceding clause can only be waived in
            writing.
          </p>
          &nbsp;
          <p style={{ fontWeight: "400" }}>
            These terms of use were created using 
            <a href="https://termly.io/products/terms-and-conditions-generator/?ftseo">
              Termly’s Terms and Conditions Generator
            </a>
            .
          </p>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicy
